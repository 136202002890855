import React from 'react';
import { css, styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import Drawer from '@mui/material/Drawer';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Select from '@mui/material/Select';
import PageHeader from 'components/PageHeader/PageHeader';
import { QUOTE_STATUS } from 'utils/types';
import { Action, salesOrderStatusBackgroundColorMap } from 'utils/constants';
import { useAuthContext } from 'contexts/AuthContext/AuthContext';
import { useQueryListVendors } from 'hooks/queries/useQueryListVendors/useQueryListVendors';
import Button from '../Button/Button';
import { Category } from '../../routes/Clients/Clients';
import { PurchaseHistoryClient, Supervisor } from '__generated__/graphql';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { Market } from 'routes/ClientDetails/components/TransactionHistory/TransactionHistory';
import { ModalGlobalStyle } from 'components/ModalGlobalStyles/ModalGlobalStyles';

const DROPDOWN_STYLE = {
  PaperProps: {
    sx: {
      maxHeight: {
        xs: '30rem',
      },
    },
  },
};

const DrawerContent = styled('div')(
  ({ theme }) => css`
    position: relative;
    height: 100%;
    display: flex;
    justify-items: left;
    width: 32rem;
    max-width: 32rem;
    flex-direction: column;
    row-gap: 1rem;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: ${theme.colors.bevelTop};
    border-left-color: ${theme.colors.bevelLeft};
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: ${theme.colors.black};
    border-radius: 1rem;
    background-color: ${theme.colors.charcoal};
  `
);

const StyledDrawer = styled(Drawer)(
  ({ theme }) => css`
    & .MuiDrawer-paper {
      position: fixed;
      inset: 1rem 1rem 1rem auto;
      height: calc(100vh - 6rem);
      width: 32rem;
      max-width: 32rem;
      margin-top: 4rem;
      border-radius: 1rem;
    }
  `
);

const CloseIcon = styled(IconButton)(
  () => css`
    z-index: 3;
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 0.5rem;
    bottom: auto;
    left: 0.5rem;
    right: auto;
  `
);

const ScrollContainer = styled('div')(
  () => css`
    height: 100vh;
    flex-direction: column;
    padding-bottom: 2.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    display: flex;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    grid-row-gap: 2rem;
  `
);

const DrawerHeader = styled('div')(
  ({ theme }) => css`
    z-index: 2;
    background-color: ${theme.colors.charcoal};
    border-bottom: 1px solid ${theme.colors.lightBlack};
    align-items: center;
    padding: 3rem 0 0;
    position: sticky;
    font-weight: 600;
    top: 0;
  `
);

const FilterSectionWrapper = styled('div')(
  () => css`
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    flex-direction: column;
    margin: 0 0.04rem 0.5rem 0.04rem;
    padding-bottom: 1rem;
    display: flex;
  `
);

const FilterSection = styled('div')(
  ({ theme }) => css`
    grid-row-gap: 0.5rem;
    border-bottom: 1px solid ${theme.colors.lightBlack};
    flex-direction: column;
    padding-bottom: 1rem;
    display: flex;
  `
);

const FilterHeader = styled('div')(
  ({ theme }) => css`
    color: ${theme.colors.whiteOpacity50};
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
  `
);

const SortFilterSelect = styled(Select)(
  ({ theme }) => css`
    min-height: 3rem;
    height: 3rem;
    background-color: ${theme.palette.input.main};
    border: none;
    border-radius: 0.5rem;
    margin-bottom: 0;
    font-weight: 500;
    display: block;
    font-size: 0.875rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 3rem;
  `
);

const SortFilterSelectItem = styled(MenuItem)(
  () => css`
    font-size: 0.875rem;
  `
);

const StyledLabel = styled(FormControlLabel)(
  () => css`
    grid-column-gap: 0.5rem;
    text-transform: uppercase;
    align-items: center;
    margin-bottom: 0.5rem;
    padding-left: 0;
    display: flex;

    span {
      font-size: 0.75rem;
      font-weight: 600;
    }
  `
);

const StyledCheckbox = styled(Checkbox)(({ theme }) =>
  theme.unstable_sx({
    marginLeft: '0.6rem',
    width: 24,
    height: 24,
    '&.MuiSvgIcon-root': { fontSize: 12 },
    '&:hover': { borderColor: theme.colors.white },
    '&.Mui-checked': {
      color: theme.colors.lightBlue,
    },
  })
);

const SecondaryButton = styled(Button)(
  ({ theme }) => css`
    color: ${theme.colors.whiteOpacity50};
    font-size: 0.75rem;
    font-weight: 600;
    padding-top: 0.25rem;
    text-transform: uppercase;
    width: max-content;

    &:hover {
      color: ${theme.colors.white};
    }
  `
);

const ButtonsContainer = styled('div')(
  () => css`
    display: flex;
    justify-content: space-between;
  `
);

type SalesEmployee = {
  id: string;
  fullName: string;
};

type FilterMenuProps = {
  openDrawer: boolean;
  setOpenDrawer: (open: boolean) => void;
  clearFilters: () => void;
  sort?: string;
  handleSortChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sortOptions?: { label: string; value: string }[];
  statusFilter?: string;
  handleStatusFilterChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  salesRepFilter?: string;
  handleSalesRepFilterChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  salesReps?: SalesEmployee[];
  salesCoordFilter?: string;
  handleSalesCoordFilterChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  salesCoordinators?: SalesEmployee[];
  inStock?: boolean;
  handleInStockFilterChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  itemSku?: string;
  handleItemSkuFilterChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  itemSubSku?: string;
  handleItemSubSkuFilterChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  itemSkus?: string[];
  itemSubSkus?: string[];
  ignoreClientWarehouses?: boolean;
  handleIgnoreClientWarehousesChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  categoryFilter?: string;
  category?: string;
  handleCategoryFilterChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  categories?: Category[];
  handleHideInactiveChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  hideInactive?: boolean;
  handleVendorChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  vendor?: string;
  handleTypeChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  handleHideChildItemsChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  hideChildItems?: boolean;
  handleAppliedFiltersChange?: () => void;
  markets?: Market[];
  handleMarketFilterChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  marketFilter?: string;
  supervisors?: Supervisor[];
  handleSupervisorFilterChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  supervisorFilter?: string;
  transactionStatuses?: string[];
  transactionStatusFilter?: string;
  handleTransactionStatusFilterChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  transactionTypeFilter?: string;
  transactionTypeList?: string[];
  handleTransactionTypeChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  employeesLoading?: boolean;
  clientFilter?: string;
  clientList?: PurchaseHistoryClient[];
  handleClientFilterChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  useSalesCoordId?: boolean;
  restorePreviouslySelectedFilters?: () => void;
  handleSalesOrderStatusFilterChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  clientTierFilter?: string;
  clientTierOptions?: string[];
  handleClientTierFilterChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const FilterMenu: React.FC<FilterMenuProps> = ({
  openDrawer,
  setOpenDrawer,
  clearFilters,
  sort,
  handleSortChange,
  sortOptions,
  statusFilter,
  handleStatusFilterChange,
  salesRepFilter,
  handleSalesRepFilterChange,
  salesReps,
  salesCoordFilter,
  handleSalesCoordFilterChange,
  salesCoordinators,
  inStock,
  handleInStockFilterChange,
  itemSku,
  handleItemSkuFilterChange,
  itemSubSku,
  handleItemSubSkuFilterChange,
  itemSkus,
  itemSubSkus,
  categoryFilter,
  handleCategoryFilterChange,
  categories,
  handleHideInactiveChange,
  hideInactive,
  handleVendorChange,
  vendor,
  handleTypeChange,
  type,
  handleHideChildItemsChange,
  hideChildItems,
  handleAppliedFiltersChange,
  markets,
  handleMarketFilterChange,
  marketFilter,
  supervisors,
  handleSupervisorFilterChange,
  supervisorFilter,
  transactionStatuses,
  transactionStatusFilter,
  handleTransactionStatusFilterChange,
  transactionTypeFilter,
  transactionTypeList,
  handleTransactionTypeChange,
  clientFilter,
  clientList,
  handleClientFilterChange,
  employeesLoading,
  useSalesCoordId,
  restorePreviouslySelectedFilters,
  handleSalesOrderStatusFilterChange,
  clientTierFilter,
  clientTierOptions,
  handleClientTierFilterChange,
}) => {
  const { can } = useAuthContext();

  const { data: vendorData } = useQueryListVendors();

  const handleApplyFiltersClicked = () => {
    handleAppliedFiltersChange && handleAppliedFiltersChange();
    setOpenDrawer(false);
  };

  const closeModalAndRestoreFilters = () => {
    setOpenDrawer(false);
    restorePreviouslySelectedFilters && restorePreviouslySelectedFilters();
  };

  return (
    <>
      <ModalGlobalStyle $isModalOpen={openDrawer} />
      <StyledDrawer
        ModalProps={{
          slotProps: { backdrop: { invisible: true } },
        }}
        disableScrollLock={true}
        anchor='right'
        open={openDrawer}
        onClose={closeModalAndRestoreFilters}
      >
        <DrawerContent>
          <ScrollContainer>
            <DrawerHeader>
              <PageHeader header='Filter & Sort' variant='h3' />
            </DrawerHeader>
            <FilterSectionWrapper>
              {sortOptions && handleSortChange && (
                <FilterSection>
                  <FilterHeader>Sort</FilterHeader>
                  <SortFilterSelect
                    value={sort}
                    onChange={handleSortChange}
                    IconComponent={ExpandMoreIcon}
                    displayEmpty
                  >
                    <SortFilterSelectItem value='' disabled>
                      Select one...
                    </SortFilterSelectItem>
                    {sortOptions.map((option) => (
                      <SortFilterSelectItem key={option.value} value={option.value}>
                        {option.label}
                      </SortFilterSelectItem>
                    ))}
                  </SortFilterSelect>
                </FilterSection>
              )}
              {handleStatusFilterChange && (
                <FilterSection>
                  <FilterHeader>Filter By Status</FilterHeader>
                  <FormGroup>
                    {Object.values(QUOTE_STATUS).map(
                      (status) =>
                        status !== QUOTE_STATUS.APPROVED && (
                          <StyledLabel
                            key={status}
                            control={
                              <StyledCheckbox
                                sx={{ fontSize: 40 }}
                                checked={statusFilter?.includes(status)}
                                onChange={handleStatusFilterChange}
                                name={status}
                              />
                            }
                            label={status}
                          />
                        )
                    )}
                  </FormGroup>
                </FilterSection>
              )}
              {handleSalesOrderStatusFilterChange && (
                <FilterSection>
                  <FilterHeader>Filter By Status</FilterHeader>
                  <FormGroup>
                    {Object.keys(salesOrderStatusBackgroundColorMap).map((status) => (
                      <StyledLabel
                        key={status}
                        control={
                          <StyledCheckbox
                            sx={{ fontSize: 40 }}
                            checked={statusFilter?.includes(status)}
                            onChange={handleSalesOrderStatusFilterChange}
                            name={status}
                          />
                        }
                        label={status}
                      />
                    ))}
                  </FormGroup>
                </FilterSection>
              )}
              {transactionStatuses && handleTransactionStatusFilterChange && (
                <FilterSection>
                  <FilterHeader>Filter by Status</FilterHeader>
                  <SortFilterSelect
                    value={transactionStatusFilter}
                    onChange={handleTransactionStatusFilterChange}
                    IconComponent={ExpandMoreIcon}
                    displayEmpty
                    MenuProps={DROPDOWN_STYLE}
                  >
                    <SortFilterSelectItem value='' disabled>
                      Select one...
                    </SortFilterSelectItem>
                    {transactionStatuses.map((status, i) => (
                      <SortFilterSelectItem key={i} value={status}>
                        {status}
                      </SortFilterSelectItem>
                    ))}
                  </SortFilterSelect>
                </FilterSection>
              )}
              {salesReps && handleSalesRepFilterChange && can('Quotation', Action.ViewAll) && (
                <FilterSection>
                  <FilterHeader>Filter by Sales Representative</FilterHeader>
                  <SortFilterSelect
                    value={salesRepFilter}
                    onChange={handleSalesRepFilterChange}
                    IconComponent={ExpandMoreIcon}
                    displayEmpty
                    MenuProps={DROPDOWN_STYLE}
                  >
                    <SortFilterSelectItem value='' disabled>
                      Select one...
                    </SortFilterSelectItem>
                    {employeesLoading ? (
                      <MenuItem disabled>
                        <LoadingSpinner loading={employeesLoading} />
                      </MenuItem>
                    ) : (
                      salesReps.map((employee) => (
                        <SortFilterSelectItem key={employee.id} value={employee.id}>
                          {employee.fullName}
                        </SortFilterSelectItem>
                      ))
                    )}
                  </SortFilterSelect>
                </FilterSection>
              )}
              {salesCoordinators && handleSalesCoordFilterChange && can('Quotation', Action.ViewAll) && (
                <FilterSection>
                  <FilterHeader>Filter by Sales Coordinator</FilterHeader>
                  <SortFilterSelect
                    value={salesCoordFilter}
                    onChange={handleSalesCoordFilterChange}
                    IconComponent={ExpandMoreIcon}
                    displayEmpty
                    MenuProps={DROPDOWN_STYLE}
                  >
                    <SortFilterSelectItem value='' disabled>
                      Select one...
                    </SortFilterSelectItem>
                    {employeesLoading ? (
                      <MenuItem disabled>
                        <LoadingSpinner loading={employeesLoading} />
                      </MenuItem>
                    ) : (
                      salesCoordinators.map((employee) => (
                        <SortFilterSelectItem
                          key={employee.id}
                          value={useSalesCoordId ? employee.id : employee.fullName}
                        >
                          {employee.fullName}
                        </SortFilterSelectItem>
                      ))
                    )}
                  </SortFilterSelect>
                </FilterSection>
              )}
              {supervisors && handleSupervisorFilterChange && can('Quotation', Action.ViewAll) && (
                <FilterSection>
                  <FilterHeader>Filter by Supervisor</FilterHeader>
                  <SortFilterSelect
                    value={supervisorFilter}
                    onChange={handleSupervisorFilterChange}
                    IconComponent={ExpandMoreIcon}
                    displayEmpty
                    MenuProps={DROPDOWN_STYLE}
                  >
                    <SortFilterSelectItem value='' disabled>
                      Select one...
                    </SortFilterSelectItem>
                    {supervisors.map((supervisor) => (
                      <SortFilterSelectItem key={supervisor.internalId} value={supervisor.internalId}>
                        {supervisor.name}
                      </SortFilterSelectItem>
                    ))}
                  </SortFilterSelect>
                </FilterSection>
              )}
              {handleInStockFilterChange && (
                <FilterSection>
                  <FilterHeader>Filter By Availability</FilterHeader>
                  <FormGroup>
                    <StyledLabel
                      control={
                        <StyledCheckbox
                          sx={{ fontSize: 40 }}
                          checked={inStock}
                          onChange={handleInStockFilterChange}
                          name='inStock'
                        />
                      }
                      label={'Available'}
                    />
                  </FormGroup>
                  <FormGroup>
                    <StyledLabel
                      control={
                        <StyledCheckbox
                          sx={{ fontSize: 40 }}
                          checked={hideInactive}
                          onChange={handleHideInactiveChange}
                          name='hideInactive'
                        />
                      }
                      label={'Hide Inactive'}
                    />
                  </FormGroup>
                  <FormGroup>
                    <StyledLabel
                      control={
                        <StyledCheckbox
                          sx={{ fontSize: 40 }}
                          checked={hideChildItems}
                          onChange={handleHideChildItemsChange}
                          name='hideChildItems'
                        />
                      }
                      label={'Hide Child Items'}
                    />
                  </FormGroup>
                </FilterSection>
              )}
              {markets && handleMarketFilterChange && can('Quotation', Action.ViewAll) && (
                <FilterSection>
                  <FilterHeader>Filter by Market</FilterHeader>
                  <SortFilterSelect
                    value={marketFilter}
                    onChange={handleMarketFilterChange}
                    IconComponent={ExpandMoreIcon}
                    displayEmpty
                    MenuProps={DROPDOWN_STYLE}
                    name='market'
                  >
                    <SortFilterSelectItem value='' disabled>
                      Select one...
                    </SortFilterSelectItem>
                    {markets.map((market, i) => (
                      <SortFilterSelectItem key={market.internalId} value={market.internalId}>
                        {market.name}
                      </SortFilterSelectItem>
                    ))}
                  </SortFilterSelect>
                </FilterSection>
              )}
              {itemSkus && handleItemSkuFilterChange && (
                <FilterSection>
                  <FilterHeader>Filter by SKU</FilterHeader>
                  <SortFilterSelect
                    value={itemSku}
                    onChange={handleItemSkuFilterChange}
                    IconComponent={ExpandMoreIcon}
                    displayEmpty
                    MenuProps={DROPDOWN_STYLE}
                    name='itemSku'
                  >
                    <SortFilterSelectItem value='' disabled>
                      Select one...
                    </SortFilterSelectItem>
                    {itemSkus.map((sku) => (
                      <SortFilterSelectItem key={sku} value={sku}>
                        {sku}
                      </SortFilterSelectItem>
                    ))}
                  </SortFilterSelect>
                </FilterSection>
              )}
              {itemSubSkus && handleItemSubSkuFilterChange && (
                <FilterSection>
                  <FilterHeader>Filter by SUB SKU</FilterHeader>
                  <SortFilterSelect
                    value={itemSubSku}
                    onChange={handleItemSubSkuFilterChange}
                    IconComponent={ExpandMoreIcon}
                    displayEmpty
                    MenuProps={DROPDOWN_STYLE}
                    name='itemSubSku'
                  >
                    <SortFilterSelectItem value='' disabled>
                      Select one...
                    </SortFilterSelectItem>
                    {itemSubSkus?.map((subSku) => (
                      <SortFilterSelectItem key={subSku} value={subSku}>
                        {subSku}
                      </SortFilterSelectItem>
                    ))}
                  </SortFilterSelect>
                </FilterSection>
              )}
              {categories && handleCategoryFilterChange && (
                <FilterSection>
                  <FilterHeader>Filter by Category</FilterHeader>
                  <SortFilterSelect
                    value={categoryFilter}
                    onChange={handleCategoryFilterChange}
                    IconComponent={ExpandMoreIcon}
                    displayEmpty
                    MenuProps={DROPDOWN_STYLE}
                    name='category'
                  >
                    <SortFilterSelectItem value='' disabled>
                      Select one...
                    </SortFilterSelectItem>
                    {categories.map((category) => (
                      <SortFilterSelectItem key={category.id} value={category.id}>
                        {category.category}
                      </SortFilterSelectItem>
                    ))}
                  </SortFilterSelect>
                </FilterSection>
              )}
              {vendorData && handleVendorChange && (
                <FilterSection>
                  <FilterHeader>Filter by vendor</FilterHeader>
                  <SortFilterSelect
                    value={vendor}
                    onChange={handleVendorChange}
                    IconComponent={ExpandMoreIcon}
                    displayEmpty
                    MenuProps={DROPDOWN_STYLE}
                    name='vendor'
                  >
                    <SortFilterSelectItem value='' disabled>
                      Select one...
                    </SortFilterSelectItem>

                    {vendorData?.listVendors.vendors.map((vendor) => (
                      <SortFilterSelectItem key={vendor.id} value={vendor.id}>
                        {vendor.name}
                      </SortFilterSelectItem>
                    ))}
                  </SortFilterSelect>
                </FilterSection>
              )}
              {handleTypeChange && (
                <FilterSection>
                  <FilterHeader>Filter by type</FilterHeader>
                  <SortFilterSelect
                    value={type}
                    onChange={handleTypeChange}
                    IconComponent={ExpandMoreIcon}
                    displayEmpty
                    MenuProps={DROPDOWN_STYLE}
                    name='type'
                  >
                    <SortFilterSelectItem value='' disabled>
                      Select one...
                    </SortFilterSelectItem>
                    <SortFilterSelectItem value='DescriptionItem'>Description Item</SortFilterSelectItem>
                    <SortFilterSelectItem value='InventoryItem'>Inventory Item</SortFilterSelectItem>
                    <SortFilterSelectItem value='ItemGroup'>Item Group</SortFilterSelectItem>
                    <SortFilterSelectItem value='NonInventoryResaleItem'>
                      Non-inventory Resale Item
                    </SortFilterSelectItem>
                    <SortFilterSelectItem value='NonInventorySaleItem'>Non-inventory Sale Item</SortFilterSelectItem>
                    <SortFilterSelectItem value='OtherChargePurchaseItem'>
                      Other Charge Purchase Item
                    </SortFilterSelectItem>
                    <SortFilterSelectItem value='OtherChargeResaleItem'>Other Change Resale Item</SortFilterSelectItem>
                    <SortFilterSelectItem value='OtherChargeSaleItem'>Other Charge Sale Item</SortFilterSelectItem>
                    <SortFilterSelectItem value='ServiceSaleItem'>Service Sale Item</SortFilterSelectItem>
                    <SortFilterSelectItem value='ServiceResaleItem'>Service Resale Item</SortFilterSelectItem>
                  </SortFilterSelect>
                </FilterSection>
              )}
              {transactionTypeList && handleTransactionTypeChange && (
                <FilterSection>
                  <FilterHeader>Filter by Document Type</FilterHeader>
                  <SortFilterSelect
                    value={transactionTypeFilter}
                    onChange={handleTransactionTypeChange}
                    IconComponent={ExpandMoreIcon}
                    displayEmpty
                    MenuProps={DROPDOWN_STYLE}
                    name='transactionType'
                  >
                    <SortFilterSelectItem value='' disabled>
                      Select one...
                    </SortFilterSelectItem>
                    {transactionTypeList.map((type, i) => (
                      <SortFilterSelectItem key={i} value={type}>
                        {type === 'Estimate' ? 'Quote' : type.split(/(?=[A-Z])/).join(' ')}
                      </SortFilterSelectItem>
                    ))}
                  </SortFilterSelect>
                </FilterSection>
              )}
              {clientList && handleClientFilterChange && (
                <FilterSection>
                  <FilterHeader>Filter by Client</FilterHeader>
                  <SortFilterSelect
                    value={clientFilter}
                    onChange={handleClientFilterChange}
                    IconComponent={ExpandMoreIcon}
                    displayEmpty
                    MenuProps={DROPDOWN_STYLE}
                    name='clientId'
                  >
                    <SortFilterSelectItem value='' disabled>
                      Select one...
                    </SortFilterSelectItem>
                    {clientList.map((client) => (
                      <SortFilterSelectItem key={client.id} value={client.id}>
                        {client.name}
                      </SortFilterSelectItem>
                    ))}
                  </SortFilterSelect>
                </FilterSection>
              )}
              {clientTierOptions && handleClientTierFilterChange && (
                <FilterSection>
                  <FilterHeader>Filter by Client Tier</FilterHeader>
                  <SortFilterSelect
                    value={clientTierFilter}
                    onChange={handleClientTierFilterChange}
                    IconComponent={ExpandMoreIcon}
                    displayEmpty
                    MenuProps={DROPDOWN_STYLE}
                    name='clientTier'
                  >
                    <SortFilterSelectItem value='' disabled>
                      Select one...
                    </SortFilterSelectItem>
                    {clientTierOptions.map((tier) => (
                      <SortFilterSelectItem key={tier} value={tier}>
                        {tier}
                      </SortFilterSelectItem>
                    ))}
                  </SortFilterSelect>
                </FilterSection>
              )}
              <ButtonsContainer>
                <SecondaryButton onClick={clearFilters}>Clear Filters</SecondaryButton>
                <SecondaryButton onClick={closeModalAndRestoreFilters}>Cancel</SecondaryButton>
                <Button variant='outlined' onClick={() => handleApplyFiltersClicked()}>
                  Apply Filters
                </Button>
              </ButtonsContainer>
            </FilterSectionWrapper>
          </ScrollContainer>
        </DrawerContent>
      </StyledDrawer>
    </>
  );
};

export default FilterMenu;
