import React, { useEffect, useState } from 'react';
import { css, styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { useParams, Link } from 'react-router-dom';
import { useQueryGetItem } from 'hooks/queries/useQueryGetItem/useQueryGetItem';
import { Tabs, Tab, TabPanel, TabContainer } from 'components/TabNavigation/TabNavigation';
import PageHeader from 'components/PageHeader/PageHeader';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { PurchaseHistoryTable } from './components/PurchaseHistoryTable/PurchaseHistoryTable';
import InventoryStatusTable from './components/InventoryStatusTable/InventoryStatusTable';
import { useAuthContext } from 'contexts/AuthContext/AuthContext';
import { Overview } from './components/Overview';
import { useError } from 'hooks/util/useError';
import Flex from 'components/Flex/Flex';

export const StyledLink = styled(Link)(
  ({ theme }) => css`
    background-color: ${theme.colors.blue};
    border-radius: 1rem;
    color: white;
    height: 1.625rem;
    padding: 0.25rem 1rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
    text-decoration: none;
    font-size: 0.75rem;
    font-weight: 700;
    border: none;

    &:hover,
    &:focus {
      border: none;
      background-color: ${theme.colors.hoverBlue};
    }
  `
);

const ItemDetails: React.FC = () => {
  const { itemId } = useParams();
  const [tab, setTab] = useState(0);
  const { employeeId } = useAuthContext();
  const { data, loading, error } = useQueryGetItem({ id: itemId ?? '' }, { skip: !employeeId });
  const raiseError = useError();

  useEffect(() => {
    if (error) {
      raiseError(error.message);
    }
  }, [raiseError, error]);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  if (loading || !data) {
    return (
      <Flex w100 styles={{ height: 'calc(100vh - 8rem)' }} center>
        <LoadingSpinner loading={true} size={92} />
      </Flex>
    );
  }

  return (
    <Container maxWidth={false}>
      <PageHeader variant='h1' header='Item Details' />
      <Flex w100 center>
        <TabContainer>
          <div>
            <h3 style={{ marginTop: 0 }}>{data.getItem?.itemId ?? ''}</h3>
            <h4 style={{ marginTop: 0 }}>{data.getItem?.description ?? ''}</h4>
          </div>
          <StyledLink to={'/items'}>Back to Item Search</StyledLink>
          <Tabs value={tab} onChange={handleChange} initialSelectedIndex={1}>
            <Tab disableRipple label='Overview' />
            <Tab disableRipple label='Item History' />
            <Tab disableRipple label='Inventory' />
          </Tabs>
          <TabPanel value={tab} index={0}>
            <Overview item={data.getItem} />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <PurchaseHistoryTable item={data.getItem} />
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <InventoryStatusTable item={data.getItem} />
          </TabPanel>
        </TabContainer>
      </Flex>
    </Container>
  );
};

export default ItemDetails;
