import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { GetItemSkusDocument, GetItemSkusQuery, GetItemSkusQueryVariables } from '__generated__/graphql';

export const useQueryGetItemSkus = (
  options?: QueryHookOptions<GetItemSkusQuery, GetItemSkusQueryVariables>
): QueryResult<GetItemSkusQuery, GetItemSkusQueryVariables> => {
  return useQuery(GetItemSkusDocument, {
    ...options,
  });
};
