import { useCallback } from 'react';

// Manage local storage with a specified prefix on the keys
const useLocalStorageWithPrefix = (prefix: string) => {
  const getStorageKey = useCallback(
    (key: string, defaultValue?: string) => {
      try {
        return window.localStorage.getItem(prefix + key) ?? defaultValue ?? '';
      } catch (error) {
        console.error('Failed to access localStorage:', error);
        return '';
      }
    },
    [prefix]
  );

  const setStorageKey = useCallback(
    (key: string, value: string) => {
      try {
        window.localStorage.setItem(prefix + key, value);
      } catch (error) {
        console.error('Failed to access localStorage:', error);
      }
    },
    [prefix]
  );

  return { getStorageKey, setStorageKey };
};

export default useLocalStorageWithPrefix;
