import { CellContext } from '@tanstack/react-table';
import Flex from 'components/Flex/Flex';
import { useQuoteContext } from 'contexts/QuoteContext/QuoteContext';
import { ItemTableItem } from 'contexts/QuoteContext/useItems';
import { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Maybe } from 'yup';
import Input from '@mui/material/Input';

const EditableCellInput = styled(Input)(
  () => css`
    all: unset;
    background: transparent;
    width: 100%;
    resize: none;
    display: block;
    margin-bottom: 10px;
    border-bottom: 0px;

    &:before {
      border-bottom: 0px;
    }

    &:hover:not(.Mui-disabled, .Mui-error):before {
      border-bottom: 0px;
    }
  `
);

type UnitCostCellProps = {
  info: CellContext<
    ItemTableItem,
    {
      id: string;
      unitCost: Maybe<number> | undefined;
    }
  >;
  onBlurCallback?: () => void;
};

function unitCostToString(unitCost: Maybe<number>) {
  return typeof unitCost === 'number' ? unitCost?.toFixed(2) : '0.00';
}

function UnitCostCell({ info, onBlurCallback }: UnitCostCellProps) {
  const { updateItemField } = useQuoteContext();
  const { unitCost } = info.getValue();

  const [value, setValue] = useState(unitCostToString(unitCost));

  // If changed externally, sync this cell's state with that
  useEffect(() => {
    setValue(unitCostToString(unitCost));
  }, [unitCost]);

  const ref = useRef<HTMLTextAreaElement | null>(null);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value);
  }

  function handleBlur() {
    updateItemField(info.row.index, 'unitCost', Number(value).toFixed(2));
    onBlurCallback?.();
  }

  return (
    <Flex column gap={0.5}>
      <EditableCellInput
        ref={ref}
        type='number'
        value={value}
        onFocus={(e) => e.target.select()}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </Flex>
  );
}

export default UnitCostCell;
