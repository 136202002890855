import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import { ColumnFiltersState, SortingState } from '@tanstack/react-table';
import FilterButton from 'components/FilterButton/FilterButton';
import Flex from 'components/Flex/Flex';
import Search from 'components/Search/Search';
import { useQueryEmployeeReference } from 'hooks/queries/useQueryEmployeeReference/useQueryEmployeeReference';
import {
  STATUS_FILTERS_KEY,
  GLOBAL_FILTERS_KEY,
  SORT_FILTERS_KEY,
  SALES_REP_FILTERS_KEY,
  SALES_COORD_FILTERS_KEY,
  MARKET_FILTERS_KEY,
  SUPERVISOR_FILTERS_KEY,
  CLIENT_TIER_FILTERS_KEY,
  SALES_MANAGER_ROLE,
} from 'utils/constants';
import FilterMenu from 'components/FilterMenu/FilterMenu';
import { useQueryGetDepartments } from 'hooks/queries/useQueryGetDepartments/useQueryGetDepartments';
import { useQueryGetSupervisors } from 'hooks/queries/useQueryGetSupervisors/useQueryGetSupervisors';
import { useAuthContext } from 'contexts/AuthContext/AuthContext';
import { useUserContext } from 'contexts/UserContext/UserContext';
import useLocalStorageWithPrefix from 'hooks/util/useLocalStorageWithPrefix';

type Props = {
  transactionType: TransactionType;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
  columnFilters: ColumnFiltersState;
  setColumnFilters: React.Dispatch<React.SetStateAction<ColumnFiltersState>>;
  setGlobalFilter: (value: string) => void;
};

export enum TransactionType {
  Quote = 'quote_',
  SalesOrder = 'salesOrder_',
}

const TransactionSearchBar: React.FC<Props> = ({
  transactionType,
  setSorting,
  columnFilters,
  setColumnFilters,
  setGlobalFilter,
}) => {
  const { getStorageKey, setStorageKey } = useLocalStorageWithPrefix(transactionType);

  const initStatusFilter = getStorageKey(STATUS_FILTERS_KEY);
  const initSearch = getStorageKey(GLOBAL_FILTERS_KEY);
  const initSort = getStorageKey(SORT_FILTERS_KEY, 'createdDate,desc');
  const initSalesRep = getStorageKey(SALES_REP_FILTERS_KEY);
  const initSalesCoord = getStorageKey(SALES_COORD_FILTERS_KEY);
  const initMarket = getStorageKey(MARKET_FILTERS_KEY);
  const initSupervisor = getStorageKey(SUPERVISOR_FILTERS_KEY);
  const initClientTier = getStorageKey(CLIENT_TIER_FILTERS_KEY);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [sort, setSort] = useState<string>(initSort);
  const [statusFilter, setStatusFilter] = useState<string>(initStatusFilter);
  const [searchText, setSearchText] = useState<string>(initSearch);
  const [salesRepFilter, setSalesRepFilter] = useState<string>(initSalesRep);
  const [salesCoordFilter, setSalesCoordFilter] = useState<string>(initSalesCoord);
  const [marketFilter, setMarketFilter] = useState<string>(initMarket);
  const [supervisorFilter, setSupervisorFilter] = useState<string>(initSupervisor);
  const [clientTierFilter, setClientTierFilter] = useState<string>('');

  const employeeAborterRef = useRef(new AbortController());
  const { data: employeeData, loading: employeesLoading } = useQueryEmployeeReference(
    { supervisorId: supervisorFilter || '' },
    {
      context: {
        fetchOptions: {
          signal: employeeAborterRef.current.signal,
        },
      },
    }
  );
  const { data: departmentsData } = useQueryGetDepartments();
  const { data: supervisorsData } = useQueryGetSupervisors();

  const markets = useMemo(
    () =>
      departmentsData?.getDepartments
        .map((department) => {
          return {
            name: department.parent?.name ? `${department.parent.name} : ${department.name}` : `${department.name}`,
            internalId: department.id,
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name)),
    [departmentsData]
  );

  const filtersApplied = useMemo(() => {
    for (const _key in columnFilters) {
      const key = _key as keyof ColumnFiltersState;
      if (columnFilters[key]) {
        return true;
      }
    }
    return false;
  }, [columnFilters]);

  const [salesReps, salesCoordinators] = useMemo(() => {
    if (employeeData) {
      return [employeeData.employeeReference.salesReps, employeeData.employeeReference.salesCoordinators];
    }
    return [[], []];
  }, [employeeData]);

  const updateColumnFiltersByColumn = useCallback(
    (columnId: string, filterValue: string | string[]) => {
      setColumnFilters((prev: ColumnFiltersState) => {
        const otherFilters = [...prev.filter((item) => item.id !== columnId)];
        if (filterValue.length > 0 && filterValue[0] !== '') {
          otherFilters.push({ id: columnId, value: filterValue });
        }
        return otherFilters;
      });
    },
    [setColumnFilters]
  );

  const clearFilters = useCallback(() => {
    setSort('createdDate,desc');
    setStorageKey(SORT_FILTERS_KEY, 'createdDate,desc');
    setSalesRepFilter('');
    setStorageKey(SALES_REP_FILTERS_KEY, '');
    setSalesCoordFilter('');
    setStorageKey(SALES_COORD_FILTERS_KEY, '');
    setStatusFilter('');
    setStorageKey(STATUS_FILTERS_KEY, '');
    setMarketFilter('');
    setStorageKey(MARKET_FILTERS_KEY, '');
    setSupervisorFilter('');
    setStorageKey(SUPERVISOR_FILTERS_KEY, '');
    setSearchText('');
    setStorageKey(GLOBAL_FILTERS_KEY, '');
    setClientTierFilter('');
    setStorageKey(CLIENT_TIER_FILTERS_KEY, '');
  }, [setStorageKey]);

  const sortOptions = [
    { value: 'createdDate,desc', label: 'Date Created: Newest' },
    { value: 'createdDate,asc', label: 'Date Created: Oldest' },
    { value: 'clientTier,asc', label: 'Client Tier: Low to High' },
    { value: 'clientTier,desc', label: 'Client Tier: High to Low' },
    { value: 'client_name,asc', label: 'Client Name (A-Z)' },
    { value: 'client_name,desc', label: 'Client Name (Z-A)' },
  ];

  const clientTierOptions = ['1', '2', '3', '4'];

  const handleSortChange = (event: SelectChangeEvent<unknown>) => {
    const newValue = event.target.value as string;
    setSort(newValue);
    setStorageKey(SORT_FILTERS_KEY, newValue);
  };

  const handleStatusFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    let newValue: string;
    if (event.target.checked) {
      newValue = statusFilter ? `${statusFilter},${name}` : name;
    } else {
      newValue = statusFilter.replaceAll(`,${name}`, '').replaceAll(`${name},`, '').replaceAll(name, '');
    }
    setStatusFilter(newValue);
    setStorageKey(STATUS_FILTERS_KEY, newValue);
  };

  const handleSalesRepFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSalesRepFilter(newValue);
    setStorageKey(SALES_REP_FILTERS_KEY, newValue);
  };

  const handleSalesCoordFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSalesCoordFilter(newValue);
    setStorageKey(SALES_COORD_FILTERS_KEY, newValue);
  };

  const handleMarketFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setMarketFilter(newValue);
    setStorageKey(MARKET_FILTERS_KEY, newValue);
  };

  const handleSupervisorFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    // clear out sales rep and sales coord filters when a new supervisor is selected
    setSalesRepFilter('');
    setStorageKey(SALES_REP_FILTERS_KEY, '');
    setSalesCoordFilter('');
    setStorageKey(SALES_COORD_FILTERS_KEY, '');

    setSupervisorFilter(newValue);
    setStorageKey(SUPERVISOR_FILTERS_KEY, newValue);
  };

  const handleAppliedFiltersChange = () => {
    updateColumnFiltersByColumn('statusSearch', statusFilter);
    updateColumnFiltersByColumn('salesRepSearch', salesRepFilter);
    updateColumnFiltersByColumn('salesCoordSearch', salesCoordFilter);
    updateColumnFiltersByColumn('marketSearch', marketFilter);
    updateColumnFiltersByColumn('supervisorSearch', supervisorFilter);
    updateColumnFiltersByColumn('clientTierSearch', clientTierFilter);
  };

  const handleClientTierFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setClientTierFilter(newValue);
    setStorageKey(CLIENT_TIER_FILTERS_KEY, newValue);
  };

  // Set initial column filters
  useEffect(() => {
    updateColumnFiltersByColumn('statusSearch', initStatusFilter);
    updateColumnFiltersByColumn('salesRepSearch', initSalesRep);
    updateColumnFiltersByColumn('salesCoordSearch', initSalesCoord);
    updateColumnFiltersByColumn('marketSearch', initMarket);
    updateColumnFiltersByColumn('clientTierSearch', initClientTier);
    // Only run on mount to set initial column filters
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set initial supervisor filter
  const { roleId } = useAuthContext();
  const { user } = useUserContext();
  const hasSetInitialSupervisor = useRef<boolean>(false);
  useEffect(() => {
    // If the user is a sales manager,
    // and there is no supervisor filter saved in local storage
    // set the initial supervisor filter to the logged in user
    if (
      !hasSetInitialSupervisor.current &&
      roleId === SALES_MANAGER_ROLE &&
      !initSupervisor &&
      typeof user?.id === 'string'
    ) {
      setSupervisorFilter(user.id);
      setStorageKey(SUPERVISOR_FILTERS_KEY, user.id);
      updateColumnFiltersByColumn('supervisorSearch', user.id);
      hasSetInitialSupervisor.current = true;
    }

    // If there is a supervisor filter saved in local storage
    // use that as the initial supervisor filter
    if (!hasSetInitialSupervisor.current && initSupervisor) {
      setSupervisorFilter(initSupervisor);
      updateColumnFiltersByColumn('supervisorSearch', initSupervisor);
      setStorageKey(SUPERVISOR_FILTERS_KEY, initSupervisor);
      hasSetInitialSupervisor.current = true;
    }
  }, [user, roleId, initSupervisor, setStorageKey, updateColumnFiltersByColumn]);

  const findColumnFilterValue = (columnName: string): string => {
    return String(columnFilters.find((filter) => filter.id === columnName)?.value ?? '');
  };

  const restorePreviouslySelectedFilters = () => {
    employeeAborterRef.current.abort();
    setTimeout(() => {
      employeeAborterRef.current = new AbortController();
    }, 50);

    setSalesRepFilter(findColumnFilterValue('salesRepSearch'));
    setSalesCoordFilter(findColumnFilterValue('salesCoordSearch'));
    setStatusFilter(findColumnFilterValue('statusSearch'));
    setMarketFilter(findColumnFilterValue('marketSearch'));
    setSupervisorFilter(findColumnFilterValue('supervisorSearch'));
    setClientTierFilter(findColumnFilterValue('clientTierSearch'));
  };

  useEffect(() => {
    if (sort) {
      setSorting([{ id: sort.split(',')[0], desc: sort.split(',')[1] === 'desc' }]);
    } else {
      setSorting([]);
    }
  }, [sort, setSorting]);

  useEffect(() => {
    setGlobalFilter(searchText);
  }, [searchText, setGlobalFilter]);

  return (
    <>
      <Flex styles={{ alignItems: 'center', justifyContent: 'space-between' }} gap={1}>
        <Search
          defaultValue={searchText}
          placeholder={`Search for a ${transactionType === TransactionType.Quote ? 'quote' : 'sales order'}...`}
          onEnter={(value) => {
            setSearchText(value);
            setStorageKey(GLOBAL_FILTERS_KEY, value);
          }}
        />
        <FilterButton onClick={() => setOpenDrawer(true)} isFilterApplied={filtersApplied} />
      </Flex>
      <FilterMenu
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        sort={sort}
        handleSortChange={handleSortChange}
        sortOptions={sortOptions}
        clearFilters={clearFilters}
        statusFilter={statusFilter}
        handleStatusFilterChange={transactionType === TransactionType.Quote ? handleStatusFilterChange : undefined}
        handleSalesOrderStatusFilterChange={
          transactionType === TransactionType.SalesOrder ? handleStatusFilterChange : undefined
        }
        salesRepFilter={salesRepFilter}
        handleSalesRepFilterChange={handleSalesRepFilterChange}
        salesReps={salesReps}
        salesCoordFilter={salesCoordFilter}
        handleSalesCoordFilterChange={handleSalesCoordFilterChange}
        salesCoordinators={salesCoordinators}
        markets={markets}
        handleMarketFilterChange={handleMarketFilterChange}
        marketFilter={marketFilter}
        supervisors={supervisorsData?.getSupervisors}
        handleSupervisorFilterChange={handleSupervisorFilterChange}
        supervisorFilter={supervisorFilter}
        handleAppliedFiltersChange={handleAppliedFiltersChange}
        employeesLoading={employeesLoading}
        restorePreviouslySelectedFilters={restorePreviouslySelectedFilters}
        clientTierFilter={clientTierFilter}
        handleClientTierFilterChange={handleClientTierFilterChange}
        clientTierOptions={clientTierOptions}
      />
    </>
  );
};

export default TransactionSearchBar;
