import React, { useState } from 'react';
import { AvatarGroup, Popover, Stack, Typography } from '@mui/material';
import OwnerAvatar from '../OwnerAvatar/OwnerAvatar';
import { QuoteSalesRep, QuoteSalesCoordinator, StitchRelation } from '__generated__/graphql';
import { styled, css } from '@mui/material/styles';

const PopoverWrapper = styled('button')(
  () => css`
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    width: 100%;
    height: 100%;
  `
);

const PopoverContentContainer = styled('div')<{ $width?: string; $gap?: string }>(
  ({ theme, $width, $gap = '0.5rem' }) => css`
    display: flex;
    background-color: ${theme.palette.input.main};
    gap: ${$gap};
    flex-wrap: wrap;
    ${$width && `width: ${$width}`};
    padding: 1rem;
    justify-content: center;
    align-items: center;
    box-shadow: ${theme.colors.blackOpacity50} 0 0.125rem 0.3125rem 0;
    border-radius: 0.5rem;
  `
);

const StyledPopover = styled(Popover)<{ $offset?: string }>(
  ({ $offset = '-1rem' }) => css`
    margin-top: ${$offset};
  `
);

export type OwnerAvatarProps = {
  salesRep?: QuoteSalesRep | StitchRelation | null;
  salesCoordintor?: QuoteSalesCoordinator | StitchRelation | null;
};

const OwnerAvatars: React.FC<OwnerAvatarProps> = ({ salesRep, salesCoordintor }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [ownerPopoverAnchor, setOwnerPopoverAnchor] = useState<HTMLButtonElement | null>(null);
  const [selectedOwnerIndex, setSelectedOwnerIndex] = useState<number | undefined>();

  const handleOwnerClick = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>, index?: number) => {
    event.stopPropagation();
    event.preventDefault();
    setOwnerPopoverAnchor(event.currentTarget as HTMLButtonElement);
    setSelectedOwnerIndex(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOwnerPopoverAnchor(null);
  };

  const openMorePopover = Boolean(anchorEl);
  const openOwnerPopover = Boolean(ownerPopoverAnchor);
  const id = openMorePopover ? 'simple-popover' : undefined;

  const owners = [];
  if (salesCoordintor) {
    owners.push(salesCoordintor);
  }
  if (salesRep) {
    owners.push(salesRep);
  }

  return (
    <div>
      <Stack direction='column' spacing={1}>
        <PopoverWrapper aria-describedby={id} variant='contained' onClick={handleOwnerClick}>
          <AvatarGroup max={2}>
            {owners.map((owner, index) => (
              <OwnerAvatar
                key={index}
                owner={owner}
                ownerRole={index === 0 ? 'coordinator' : 'rep'}
                onClick={(e) => handleOwnerClick(e, index)}
              />
            ))}
          </AvatarGroup>
        </PopoverWrapper>
      </Stack>

      <StyledPopover
        open={openOwnerPopover}
        anchorEl={ownerPopoverAnchor}
        onClose={handleClose}
        sx={{
          pointerEvents: 'none',
          marginLeft: '1rem',
          marginTop: '0.1rem',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <PopoverContentContainer $width='11.626rem'>
          {selectedOwnerIndex !== undefined ? (
            <Typography>{owners[selectedOwnerIndex].name}</Typography>
          ) : (
            <>
              {owners.slice(1).map((owner, index) => (
                <Typography key={index}>{owner.name}</Typography>
              ))}
            </>
          )}
        </PopoverContentContainer>
      </StyledPopover>
    </div>
  );
};

export default React.memo(OwnerAvatars);
