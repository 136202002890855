import React, { CSSProperties } from 'react';
import { css, styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import LottieNavButton from 'components/LottieNavButton/LottieNavButton';
import { SubNavItem } from 'layout/Layout';
import FancyLottieNavButton from '../FancyLottieNavButton/FancyLottieNavButton';
import { Tooltip } from 'components/Tooltip/Tooltip';
import IconButton from '@mui/material/IconButton';

const NavContainer = styled('div')(
  ({ theme }) => css`
    align-items: center;
    background-color: ${theme.colors.charcoal};
    display: flex;
    gap: 1rem;
    height: 4rem;
    left: 4rem;
    justify-content: flex-end;
    padding: 0.5rem 1rem;
    position: fixed;
    width: calc(100vw - 4rem);
    z-index: 1;
  `
);

export const SubNav = styled('div')(
  () => css`
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
    height: 100%;
    margin-right: 1.78125rem;
  `
);

const TextNavButton = styled(Button)<ButtonProps>(
  ({ theme }) => css`
    color: ${theme.colors.white};
    border-radius: 1rem;
    border: 0.0625rem solid transparent;
    padding: 0.25rem 1rem;
    height: auto;

    &:hover {
      border: 0.0625rem solid ${theme.colors.lightBlack};
      background-color: transparent;
    }
  `
);

const NotificationContainer = styled('div')(
  () => css`
    position: relative;
  `
);

const NotificationBadge = styled('div')(
  ({ theme }) => css`
    background: ${theme.colors.red};
    border-radius: 50%;
    width: 10px;
    height: 10px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  `
);

type Props = {
  subNavItems?: SubNavItem[];
};

const lottieStyle: CSSProperties = {
  width: 19,
  height: 19,
};

export const subNavItemToComponent = (item: SubNavItem) => {
  switch (item.type) {
    case 'text':
      return (
        <TextNavButton variant='outlined' key={`subnav-link-${item.value}`} onClick={item.onClick}>
          {item.value}
        </TextNavButton>
      );
    case 'icon':
      return (
        <Tooltip key={`subnav-link-${item.tooltip}`} title={item.tooltip}>
          <NotificationContainer>
            {item.showNotification && <NotificationBadge />}
            <LottieNavButton
              disabled={item.disabled}
              animationData={item.imageData}
              style={lottieStyle}
              speed={1}
              onClick={item.onClick}
            />
          </NotificationContainer>
        </Tooltip>
      );
    case 'fancyicon':
      return (
        <Tooltip key={`subnav-link-${item.tooltip}`} title={item.tooltip}>
          <FancyLottieNavButton
            animationData={item.imageData}
            style={lottieStyle}
            speed={1}
            onClick={item.onClick}
            label={item.tooltip}
            nudge={item.nudge}
          />
        </Tooltip>
      );
    case 'muiicon':
      return (
        <Tooltip key={`subnav-link-${item.tooltip}`} title={item.tooltip}>
          <IconButton onClick={item.onClick} disabled={item.disabled}>
            <item.icon />
          </IconButton>
        </Tooltip>
      );
  }
};

const TopNav: React.FC<Props> = ({ subNavItems }) => {
  return (
    <NavContainer>
      <SubNav>{subNavItems?.map((item) => subNavItemToComponent(item))}</SubNav>
    </NavContainer>
  );
};

export default TopNav;
