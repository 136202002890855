import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  Button,
  ClickAwayListener,
  Grow,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  useTheme,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import { Clear, CopyAll } from '@mui/icons-material';
import { css, styled } from '@mui/material/styles';

type ActionMenuProps = {
  visible: boolean;
  objectName: string;
  onDuplicate?: () => void;
  onDelete?: () => void;
  onEdit?: () => void;
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: '.5rem',
  borderTop: `1px solid ${theme.colors.bevelTop}`,
  borderLeft: `1px solid ${theme.colors.bevelLeft}`,
  boxShadow: '0 2px 5px rgba(0, 0, 0, .2)',
}));

const StyledIcon = styled(MoreVertIcon)(
  ({ theme, $visible }) => css`
    transition: all 313ms ease-in-out;
    font-size: 1.3rem;
    cursor: pointer;
    color: ${theme.palette.text.primary};
  `
);

const StyledButton = styled('button')(
  () => css`
    background: transparent;
    border: none;
  `
);

const ActionMenu: React.FC<ActionMenuProps> = ({ visible, onDuplicate, onDelete, onEdit, objectName }) => {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const theme = useTheme();

  const handleToggle = useCallback(() => {
    setOpen((cur) => !cur);
  }, []);

  const handleClose = useCallback(() => setOpen(false), []);

  const handleDuplicate = useCallback(() => {
    onDuplicate && onDuplicate();
    handleClose();
  }, [handleClose, onDuplicate]);

  const handleDelete = useCallback(() => {
    onDelete && onDelete();
    handleClose();
  }, [handleClose, onDelete]);

  const handleEdit = useCallback(() => {
    onEdit && onEdit();
    handleClose();
  }, [handleClose, onEdit]);

  const handleListKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }, []);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const icoStyles = useMemo(
    () => ({ color: theme.typography.menuitem.color, fontSize: '1rem' }),
    [theme.typography.menuitem.color]
  );

  return (
    <>
      <StyledButton
        ref={anchorRef}
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
      >
        <StyledIcon $visible={visible} />
      </StyledButton>
      <Popper
        sx={{ 'z-index': '1' }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement='auto-end'
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'auto-end' ? 'left top' : 'left bottom',
            }}
          >
            <StyledPaper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id='composition-menu'
                  aria-labelledby='composition-button'
                  onKeyDown={handleListKeyDown}
                >
                  {onDuplicate && (
                    <MenuItem onClick={handleDuplicate}>
                      <ListItemIcon>
                        <CopyAll sx={icoStyles} />
                      </ListItemIcon>
                      <ListItemText primaryTypographyProps={{ variant: 'menuitem' }}>
                        Duplicate {objectName}
                      </ListItemText>
                    </MenuItem>
                  )}
                  {onDelete && (
                    <MenuItem onClick={handleDelete}>
                      <ListItemIcon>
                        <Clear sx={icoStyles} />
                      </ListItemIcon>
                      <ListItemText primaryTypographyProps={{ variant: 'menuitem' }}>Remove {objectName}</ListItemText>
                    </MenuItem>
                  )}
                  {onEdit && (
                    <MenuItem onClick={handleEdit}>
                      <ListItemIcon>
                        <EditIcon sx={icoStyles} />
                      </ListItemIcon>
                      <ListItemText primaryTypographyProps={{ variant: 'menuitem' }}>Edit {objectName}</ListItemText>
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </StyledPaper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default ActionMenu;
