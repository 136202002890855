import React, { useCallback, useEffect, useMemo, useState, FocusEvent, useRef } from 'react';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AddIcon from '@mui/icons-material/Add';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Tooltip } from '@mui/material';
import styled, { css } from 'styled-components';
import Flex from 'components/Flex/Flex';
import Search from 'components/Search/Search';
import PillWithClose from 'components/PillWithClose/PillWithClose';
import ItemsTable from 'routes/Quote/components/AddItems/components/ItemsTable/ItemsTable';
import { useQuoteItemsContext } from 'contexts/QuoteItemsContext/QuoteItemsContext';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { useQuoteContext } from 'contexts/QuoteContext/QuoteContext';
import { useAuthContext } from 'contexts/AuthContext/AuthContext';
import { Action, ITEMS_SELECTION_STORAGE_KEY } from 'utils/constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import { QuoteItem, Item } from '__generated__/graphql';
import PricingModal from '../PricingModal/PricingModal';
import FilterButton from 'components/FilterButton/FilterButton';
import { useQueryGetItemSkus } from 'hooks/queries/useQueryGetItemSkus/useQueryGetItemSkus';
import FilterMenu from 'components/FilterMenu/FilterMenu';
import { useQueryGetItemSubSkus } from 'hooks/queries/useQueryGetItemSubSkus/useQueryGetItemSubSkus';
import { BoldEllipsis } from 'components/BoldEllipsis/BoldEllipsis';
import { Ellipsis } from 'components/Ellipsis/Ellipsis';
import { LoadingContainer } from 'components/LoadingContainer/LoadingContainer';
import { getBinsWithoutDuplicateLocations, stripHTML } from 'utils/functions';
import { useQueryGetTBDItem } from 'hooks/queries/useQueryGetTBDItem/useQueryGetTBDItem';
import { SearchResultTable } from 'components/SearchResultTable/SearchResultTable';
import { InfiniteScrollContainer } from 'components/InfiniteScrollContainer/InfiniteScrollContainer';

type HybridItem = QuoteItem & Item;

const IconContainer = styled('span')(
  () => css`
    margin-left: 1rem;
    width: 1.8rem;
    display: inline-block;
  `
);

const StickyContainer = styled('div')(
  ({ theme }) => css`
    background-color: ${theme.colors.lightBlack};
    position: sticky;
    top: 4rem;
    z-index: 110;
    padding: 1rem 0;
    width: 100%;
    max-height: 100%;
  `
);

const tabletStyleOverrides = {
  height: '3.2rem',
  width: '3rem',
  padding: '0',
  minWidth: '0',
};

const TBDButton = styled(Button)(
  ({ theme }) => css`
    border-color: ${theme.colors.weeblyOrange};
    &:hover {
      background-color: ${theme.colors.weeblyOrange};
    }
  `
);

const StyledButton = styled(Button)(
  () => css`
    min-width: 3.25rem;
  `
);

const AddItems: React.FC = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('tablet'));
  const {
    quoteItemsPaginated,
    setQuoteItemsPaginated,
    defaultQuoteItemsPaginated,
    searchLoading,
    pagination,
    setPagination,
    fetchMoreItems,
    filters,
    setFilters,
    appliedFilters,
    setAppliedFilters,
    setPauseSearch,
    defaultItemFilters,
  } = useQuoteItemsContext();
  const [selectedItems, setSelectedItems] = useState<HybridItem[]>([]);
  const [openFiltersDrawer, setOpenFiltersDrawer] = useState<boolean>(false);
  const [pricingOpen, setPricingOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const {
    itemsTableItems,
    i2pPricing,
    addItems: addContextItems,
    setShowSnackbar,
    setSnackbarMessage,
    hasItemGroupNotification,
    setHasItemGroupNotification,
  } = useQuoteContext();
  const { can } = useAuthContext();
  const { data: itemSkusData } = useQueryGetItemSkus();
  const subSkusAborterRef = useRef(new AbortController());
  const { data: itemSubSkusData } = useQueryGetItemSubSkus(filters.itemSku, {
    context: {
      fetchOptions: {
        signal: subSkusAborterRef.current.signal,
      },
    },
  });

  const filtersApplied = useMemo(() => {
    for (const _key in filters) {
      const key = _key as keyof typeof filters;
      if (filters[key] !== defaultItemFilters[key]) {
        return true;
      }
    }
    return false;
  }, [filters, defaultItemFilters]);

  useEffect(() => {
    if (i2pPricing?.quotelines) {
      setPricingOpen(true);
    } else {
      setPricingOpen(false);
    }
  }, [i2pPricing?.quotelines]);

  //Since there's no separate view page we'll do view here
  //and gate the actual save functionality on edit permission
  const canEdit = useMemo(() => {
    return can('Quotation', Action.Edit);
  }, [can]);

  const selectItem = (itemNumber: string) => {
    if (canEdit) {
      const itemSelectionExists = selectedItems.find((item) => item.itemNumber === itemNumber);
      if (itemSelectionExists) {
        return;
      }

      const newItem = quoteItemsPaginated?.quoteItems?.find((item) => item.itemNumber === itemNumber);

      if (newItem) {
        newItem.adjCost = (newItem.unitCost ?? 0) * (1 + newItem.margin / 100);
      }

      newItem && setSelectedItems([...selectedItems, newItem as unknown as HybridItem]);
    }
  };

  const removeItem = (itemNumber: string) => {
    const newSelectedItems = selectedItems.filter((item) => item.itemNumber !== itemNumber);
    setSelectedItems(newSelectedItems);
  };

  const addItems = () => {
    // If its the first time a user is adding an item group, notify them about stock
    if (!hasItemGroupNotification && selectedItems.find((item) => item.type === 'ItemGroup')) {
      setSnackbarMessage('Some items in groups may be out of stock');
      setShowSnackbar(true);
      setHasItemGroupNotification(true);
    }
    addContextItems(selectedItems);
    setFilters({ ...filters, searchQuery: '' });
    setAppliedFilters({ ...filters, searchQuery: '' });
    setPagination({ pageSize: 20, pageIndex: 0 });
    setPauseSearch(true);
    setSearchValue('');
    setSelectedItems([]);
    setQuoteItemsPaginated(defaultQuoteItemsPaginated);
  };

  const { refetch: getTBDItem } = useQueryGetTBDItem();

  const addTBDItem = async () => {
    try {
      const { data } = await getTBDItem();
      addContextItems([data.getTBDItem as HybridItem]);
    } catch (e) {
      console.warn('Failed to fetch TBD item: ', e);
    }
  };

  const fetchNextPage = () => {
    setPagination({
      pageIndex: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
    });

    fetchMoreItems();
  };

  const clearFilters = useCallback(() => {
    setFilters({ ...defaultItemFilters, inStock: false });
  }, [defaultItemFilters, setFilters]);

  const restorePreviouslySelectedFilters = () => {
    subSkusAborterRef.current.abort();
    setTimeout(() => {
      setFilters({ ...appliedFilters });
      subSkusAborterRef.current = new AbortController();
    }, 50);
  };

  const handleInStockFilterChange = () => {
    setFilters({ ...filters, inStock: !filters.inStock });
  };

  const handleHideInactiveChange = () => {
    setFilters((prev) => ({ ...prev, hideInactive: !prev.hideInactive }));
  };

  const handleHideChildItemsChange = () => {
    setFilters((prev) => ({ ...prev, hideChildItems: !prev.hideChildItems }));
  };

  const handleItemSkuFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, itemSku: e.target.value });
  };

  const handleItemSubSkuFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, itemSubSku: e.target.value });
  };

  const handleVendorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters((prev) => ({ ...prev, vendor: e.target.value }));
  };

  const handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters((prev) => ({ ...prev, type: e.target.value }));
  };

  const handleSearchQueryChange = (value: string) => {
    setPauseSearch(false);
    setSearchValue(value);
  };

  const handleAppliedFiltersChange = () => {
    setPauseSearch(false);
    setPagination({ pageSize: 20, pageIndex: 0 });
    setAppliedFilters(filters);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setFilters((current) => ({ ...current, searchQuery: searchValue }));
      setAppliedFilters((current) => ({ ...current, searchQuery: searchValue }));
      setPagination({ pageSize: 20, pageIndex: 0 });
    }, 500);
    return () => clearTimeout(timeout);
  }, [searchValue, setFilters, setAppliedFilters, setPagination, setPauseSearch]);

  const handleIgnoreClientWarehousesChange = () => {
    setFilters({ ...filters, ignoreClientWarehouses: !filters.ignoreClientWarehouses });
  };

  useEffect(() => {
    if (itemsTableItems.length !== 0) {
      return;
    }

    try {
      const stored = window.localStorage.getItem(ITEMS_SELECTION_STORAGE_KEY);
      const parsed = JSON.parse(stored ?? 'null') as null | Item[];

      if (parsed?.length) {
        addContextItems(parsed);
      }
    } catch (ex) {
      setSnackbarMessage('An error occurred adding items to the quote.');
      setShowSnackbar(true);
    }
  }, [itemsTableItems.length, addContextItems, setSnackbarMessage, setShowSnackbar]);

  const [selectionContainsInactive, btnTooltip] = useMemo(() => {
    const isSelected = selectedItems.reduce((acc, item) => acc || !!item.isInactive, false);
    const tooltip =
      selectedItems.length == 1
        ? 'This inactive item cannot be added to a quote until it is set to Active status in Netsuite.'
        : 'Inactive items cannot be added to a quote until they are set to Active status in Netsuite.';
    return [isSelected, tooltip];
  }, [selectedItems]);

  return (
    <>
      <StickyContainer>
        <Flex
          w100
          gap={1}
          styles={{
            justifyContent: 'space-between',
          }}
        >
          <Search
            placeholder='Search for an item by Millennium part number, Vendor part number, or item description…'
            onFocus={(e: FocusEvent<HTMLInputElement>) => e.target.select()}
            onEnter={handleSearchQueryChange}
          />
          <FilterButton onClick={() => setOpenFiltersDrawer(!openFiltersDrawer)} isFilterApplied={filtersApplied} />
          <TBDButton onClick={addTBDItem} variant='outlined'>
            <AddIcon />
            TBD
          </TBDButton>
          <FilterMenu
            hideInactive={filters.hideInactive}
            handleHideInactiveChange={handleHideInactiveChange}
            openDrawer={openFiltersDrawer}
            setOpenDrawer={setOpenFiltersDrawer}
            clearFilters={clearFilters}
            inStock={filters.inStock}
            handleInStockFilterChange={handleInStockFilterChange}
            itemSku={filters.itemSku}
            handleItemSkuFilterChange={handleItemSkuFilterChange}
            itemSubSku={filters.itemSubSku}
            handleItemSubSkuFilterChange={handleItemSubSkuFilterChange}
            itemSkus={itemSkusData?.getItemSkus?.itemSkus}
            itemSubSkus={itemSubSkusData?.getItemSubSkus.itemSubSkus}
            ignoreClientWarehouses={filters.ignoreClientWarehouses}
            handleIgnoreClientWarehousesChange={handleIgnoreClientWarehousesChange}
            handleVendorChange={handleVendorChange}
            vendor={filters.vendor}
            handleTypeChange={handleTypeChange}
            type={filters.type}
            hideChildItems={filters.hideChildItems}
            handleHideChildItemsChange={handleHideChildItemsChange}
            handleAppliedFiltersChange={handleAppliedFiltersChange}
            restorePreviouslySelectedFilters={restorePreviouslySelectedFilters}
          />
          <Tooltip title={selectionContainsInactive ? btnTooltip : ''}>
            <StyledButton
              onClick={addItems}
              variant='outlined'
              disabled={selectedItems.length <= 0 || !canEdit || selectionContainsInactive}
              sx={isTablet ? tabletStyleOverrides : {}}
            >
              {isTablet ? <AddIcon /> : 'Add to Quote'}
            </StyledButton>
          </Tooltip>
        </Flex>
      </StickyContainer>
      {selectedItems && (
        <Flex wrap gap={1} styles={{ marginBottom: '1rem' }}>
          {selectedItems.map((item) => (
            <PillWithClose key={item.itemNumber} handleClose={() => removeItem(item.itemNumber || '')}>
              {item.isInactive && (
                <Tooltip title={'Inactive'}>
                  <ErrorOutlineIcon />
                </Tooltip>
              )}
              <BoldEllipsis>{item.storeDisplayName || item.itemNumber}</BoldEllipsis>
              <Ellipsis>{item.description}</Ellipsis>
            </PillWithClose>
          ))}
        </Flex>
      )}

      <Flex center column>
        <LoadingSpinner loading={!!searchLoading}>
          {!!quoteItemsPaginated?.quoteItems?.length && (
            <InfiniteScrollContainer>
              <InfiniteScroll
                style={{ height: '40rem' }}
                dataLength={quoteItemsPaginated.quoteItems.length}
                next={fetchNextPage}
                hasMore={quoteItemsPaginated.quoteItems.length < quoteItemsPaginated.total}
                loader={
                  <LoadingContainer>
                    <LoadingSpinner loading={true} />
                  </LoadingContainer>
                }
              >
                <SearchResultTable>
                  <thead>
                    <div /> {/* Hide the overflow in corners */}
                    <tr>
                      <th>Item #</th>
                      <th>Warehouse</th>
                      <th>Available</th>
                      <th>Description</th>
                    </tr>
                  </thead>

                  <tbody>
                    {quoteItemsPaginated?.quoteItems?.map((item) => {
                      return (
                        <tr key={item.itemNumber} onClick={() => selectItem(item.itemNumber)}>
                          <td>
                            <p>
                              {item.itemNumber}
                              <IconContainer>
                                {item.isInactive && (
                                  <Tooltip title={'Item is Inactive'}>
                                    <ErrorOutlineIcon sx={{ verticalAlign: 'middle' }} />
                                  </Tooltip>
                                )}
                              </IconContainer>
                            </p>
                          </td>
                          <td>
                            {item.inventoryDetail &&
                              getBinsWithoutDuplicateLocations(item.inventoryDetail).map((bin) => (
                                <p key={bin.info?.id}>{bin.info?.name ?? ''}</p>
                              ))}
                          </td>
                          <td>
                            {item.inventoryDetail
                              ? getBinsWithoutDuplicateLocations(item.inventoryDetail).map((bin) => (
                                  <p key={bin.info?.id}>{bin.locationQuantityAvailable || 0}</p>
                                ))
                              : 0}
                          </td>
                          <td dangerouslySetInnerHTML={{ __html: stripHTML(item.description) }} />
                        </tr>
                      );
                    })}
                  </tbody>
                </SearchResultTable>
              </InfiniteScroll>
            </InfiniteScrollContainer>
          )}
        </LoadingSpinner>

        {itemsTableItems.length > 0 && <ItemsTable />}
      </Flex>
      <Flex column styles={{ alignItems: 'flex-end' }}>
        <PricingModal
          itemsTableItems={itemsTableItems}
          open={pricingOpen}
          onCloseCallback={() => setPricingOpen(false)}
        />
      </Flex>
    </>
  );
};

export default AddItems;
