import React, { useEffect, useMemo, useState } from 'react';
import Container from '@mui/material/Container';
import Flex from 'components/Flex/Flex';
import AddItems from './components/AddItems/AddItems';
import Subtotal from './components/Subtotal/Subtotal';
import WarningModal from './components/WarningModal/WarningModal';
import QuoteHeader from './components/QuoteHeader/QuoteHeader';
import ProjectDetails from './components/ProjectDetails/ProjectDetails';
import { TabContent, TabNavigation } from 'components/TabNavigation/TabNavigation';
import { QuoteItemsContextProvider } from 'contexts/QuoteItemsContext/QuoteItemsContext';
import { QuoteContextProvider, useQuoteContext } from 'contexts/QuoteContext/QuoteContext';
import { useAuthContext } from 'contexts/AuthContext/AuthContext';
import { Action } from 'utils/constants';
import { Alert, AlertTitle, Snackbar } from '@mui/material';
import { unstable_usePrompt } from 'react-router-dom';
import { QuoteSubNavContextProvider } from 'contexts/QuoteSubNavContext/QuoteSubNavContext';
import CommentModal from './components/CommentModal/CommentModal';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import FinanceWarningModal from 'components/WarningModal/WarningModal';
import { css, styled } from 'styled-components';

const QuoteHeaderContainer = styled('div')(
  () => css`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  `
);

const Quote: React.FC = () => {
  const [tabValue, setTabValue] = useState(0);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [shouldShowModal, setShouldShowModal] = useState(true);
  const {
    status,
    errorMessage,
    quote,
    projectDetailsValidated,
    showSnackbar,
    setShowSnackbar,
    snackbarMessage,
    modifiedFields,
    clientData,
  } = useQuoteContext();
  const { can } = useAuthContext();
  const [showFinanceWarning, setShowFinanceWarning] = useState(false);

  //Since there's no separate view page we'll do view here
  //and gate teh actual save functionality on edit permission
  const canViewQuote = useMemo(() => {
    return can('Quotation', Action.View);
  }, [can]);

  const handleNavigationClicked = () => {
    if (!projectDetailsValidated && shouldShowModal) {
      setShouldShowModal(false);
      setOpenWarningModal(true);
    }
  };

  // Prompts user before leaving page with back/forward history buttons and internal links
  unstable_usePrompt({
    when:
      modifiedFields.areProjectDetailsModified || modifiedFields.areItemsModified || modifiedFields.areCommentsCreated,
    message: 'Reminder: navigating away from this page will lose information until the quote is created / updated.',
  });

  useEffect(() => {
    // Prompts user before refreshing page or leaving site
    const alertUser = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = ''; // Chrome requires returnValue to be set
    };
    if (
      modifiedFields.areProjectDetailsModified ||
      modifiedFields.areItemsModified ||
      modifiedFields.areCommentsCreated
    ) {
      window.addEventListener('beforeunload', alertUser);
    }
    return () => window.removeEventListener('beforeunload', alertUser);
  }, [modifiedFields]);

  useEffect(() => {
    if (clientData?.isSlowToPay) {
      setShowFinanceWarning(true);
    }
  }, [clientData?.isSlowToPay]);

  const quoteLoading = 'loading' === status || !quote;

  if (clientData?.isPerson) {
    return (
      <Alert color={'error'}>
        <AlertTitle>Creating quotes for Individual Clients is not currently supported in the MAx tool.</AlertTitle>
      </Alert>
    );
  }

  return (
    <Container maxWidth={false}>
      <QuoteSubNavContextProvider>
        <WarningModal open={openWarningModal} onClose={() => setOpenWarningModal(false)} />
        <FinanceWarningModal
          open={showFinanceWarning}
          onClose={() => setShowFinanceWarning(false)}
          message='Avg Days to Pay Exceeds 75 days for this client. 22% or higher average margin is recommended.'
        />
        <Snackbar
          open={showSnackbar}
          autoHideDuration={20000}
          onClose={() => setShowSnackbar(false)}
          message={snackbarMessage}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        />

        <QuoteHeaderContainer>
          {'error' === status && (
            <Alert color={'error'}>
              <AlertTitle>There was a problem loading the quote</AlertTitle>
              {errorMessage}
            </Alert>
          )}
          <QuoteHeader />
          {quote && <Subtotal />}
        </QuoteHeaderContainer>

        {!canViewQuote && (
          <Alert color='error'>
            <AlertTitle>You do not have permission to view a quote</AlertTitle>
          </Alert>
        )}

        {canViewQuote && 'error' !== status && (
          <Flex w100 styles={{ height: quoteLoading ? 'calc(100vh - 8rem)' : 'auto' }} center>
            <LoadingSpinner loading={quoteLoading} size={92}>
              <CommentModal />
              <TabNavigation
                onClick={handleNavigationClicked}
                shouldShowModal={shouldShowModal}
                projectDetailsValidated={projectDetailsValidated}
                value={tabValue}
                setValue={setTabValue}
              >
                <TabContent header='Quote Details'>
                  <ProjectDetails key={quote?.id} setTabValue={setTabValue} />
                </TabContent>
                {/* Disabled for MITP-324 */}
                {/*<TabContent header='Recommended Pricing'>*/}
                {/*  <RecommendedPricing />*/}
                {/*</TabContent>*/}
                <TabContent header='Add Items'>
                  <QuoteItemsContextProvider>
                    <AddItems />
                  </QuoteItemsContextProvider>
                </TabContent>
              </TabNavigation>
            </LoadingSpinner>
          </Flex>
        )}
      </QuoteSubNavContextProvider>
    </Container>
  );
};

// Wrap the component so that it has access to the QuoteContext
const QuoteContextWrapper: React.FC = () => {
  return (
    <QuoteContextProvider>
      <Quote />
    </QuoteContextProvider>
  );
};

export default QuoteContextWrapper;
