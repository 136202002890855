import React from 'react';
import Container from '@mui/material/Container';
import { ItemSearch } from './components/ItemSearch/ItemSearch';

const ItemsSearch: React.FC = () => {
  return (
    <Container maxWidth={false}>
      <ItemSearch />
    </Container>
  );
};

export default ItemsSearch;
