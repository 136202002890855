import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Container from '@mui/material/Container';
import PageHeader from 'components/PageHeader/PageHeader';
import { PaginationState, createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Row, Spacer, Table, TableCell, TableHeaderCell } from 'components/TableComponents/TableComponents';
import { useNavigate, Link } from 'react-router-dom';
import styled, { css, useTheme } from 'styled-components';
import { useMediaQuery } from '@mui/material';
import { ListClientsQuery, LookupType } from '__generated__/graphql';
import FilterButton from 'components/FilterButton/FilterButton';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { SALES_REP_FILTERS_KEY, CLIENT_CATEGORY_FILTERS_KEY, CLIENT_SEARCH_TEXT_KEY } from 'utils/constants';
import { useQueryEmployeeReference } from 'hooks/queries/useQueryEmployeeReference/useQueryEmployeeReference';
import { useQueryGetLookup } from 'hooks/queries/useQueryGetLookup/useQueryGetLookup';
import { useQueryListClients } from 'hooks/queries/useQueryListClients/useQueryListClients';
import { getPage, seoFriendly } from 'utils/functions';
import Pagination from 'components/Pagination/Pagination';
import FilterMenu from 'components/FilterMenu/FilterMenu';
import Search from 'components/Search/Search';
import { useSubNav } from 'layout/Layout';
import Flex from 'components/Flex/Flex';
import AddBox from '@mui/icons-material/AddBox';

type Client = ListClientsQuery['listClients']['clients'][0];

type Filters = {
  categoryId?: string;
  salesRep?: string;
};

export type Category = {
  id: string;
  category: string;
};

const StyledTableCell = styled(TableCell)(
  ({ theme }) => css`
    border-right-width: 0px;
    border-left-color: ${theme.colors.darkBoundary};
  `
);

const EmailLink = styled('a')(
  () => css`
    display: block;
    height: 100%;
    color: unset;
    text-decoration: none;
  `
);

const ErrorMessage = styled('p')`
  margin-bottom: 0;
  margin-top: 0.5rem;
`;

const Clients: React.FC = () => {
  const initSalesRep = window.localStorage.getItem(SALES_REP_FILTERS_KEY) || '';
  const initCategory = window.localStorage.getItem(CLIENT_CATEGORY_FILTERS_KEY) || '';
  const initSearchText = window.localStorage.getItem(CLIENT_SEARCH_TEXT_KEY) ?? '';
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [salesRepFilter, setSalesRepFilter] = useState<string>(initSalesRep);
  const [categoryFilter, setCategoryFilter] = useState<string>(initCategory);
  const [pagination, setPagination] = useState<PaginationState>({ pageSize: 10, pageIndex: 0 });
  const [pages, setPages] = useState<number>(0);
  const navigate = useNavigate();
  const [, setSubNavItems] = useSubNav();

  useEffect(() => {
    setSubNavItems([
      {
        type: 'muiicon',
        tooltip: 'New client',
        icon: AddBox,
        onClick: () => navigate('/client'),
      },
    ]);

    return () => setSubNavItems([]);
  }, [setSubNavItems, navigate]);

  const theme = useTheme();

  const isTablet = useMediaQuery(theme.breakpoints.down('tablet'));

  const { data: categoryData } = useQueryGetLookup({ input: { type: LookupType.ClientCategory } });

  const clientCodes = useMemo(() => {
    return categoryData
      ? Object.keys(categoryData.getLookup)
          .map((categoryKey) => {
            return { id: categoryKey, category: categoryData.getLookup[categoryKey] };
          })
          .sort((a, b) => a.category.localeCompare(b.category))
      : [];
  }, [categoryData]);

  const [filters, setFilters] = useState<Filters>({
    categoryId: categoryFilter,
    salesRep: salesRepFilter,
  });
  const [appliedFilters, setAppliedFilters] = useState<Filters>({
    categoryId: categoryFilter,
    salesRep: salesRepFilter,
  });

  const [searchTerms, _setSearchTerms] = useState<string>(initSearchText);
  const setSearchTerms = useCallback(
    (value: string) => {
      _setSearchTerms(value);
      window.localStorage.setItem(CLIENT_SEARCH_TEXT_KEY, value);
    },
    [_setSearchTerms]
  );

  const handleSetPage = useCallback(
    (newPage: number) => {
      setPagination((cur) => ({ ...cur, pageIndex: newPage }));
    },
    [setPagination]
  );

  const { data, loading, error } = useQueryListClients({
    input: {
      limit: pagination.pageSize,
      page: pagination.pageIndex,
      search: searchTerms,
      ...appliedFilters,
    },
  });

  const filtersApplied = useMemo(() => {
    return Object.keys(appliedFilters).some((key) => appliedFilters[key as keyof Filters]);
  }, [appliedFilters]);

  useEffect(() => {
    setPages(Math.ceil((data?.listClients?.totalCount ?? 0) / pagination.pageSize));
  }, [data?.listClients?.totalCount, pagination.pageSize]);

  const { data: employeeData } = useQueryEmployeeReference();
  const columnHelper = createColumnHelper<Client>();

  const [salesReps] = useMemo(() => {
    if (employeeData) {
      return [employeeData.employeeReference.salesReps, employeeData.employeeReference.salesCoordinators];
    }
    return [[], []];
  }, [employeeData]);

  const columns = useMemo(
    () => [
      columnHelper.accessor((client) => client.name, {
        id: 'name',
        cell: (info) => info.getValue(),
        header: () => 'Name',
      }),
      columnHelper.accessor((client) => client.contacts, {
        id: 'primaryContact',
        cell: (info) => {
          const value = info.getValue();
          return value ? value[0]?.name : '';
        },
        header: () => 'Primary Contact',
      }),
      columnHelper.accessor((client) => client.category?.name, {
        id: 'category',
        cell: (info) => info.getValue(),
        header: () => 'Category',
      }),
      columnHelper.accessor((client) => client.salesRep, {
        id: 'salesRep',
        cell: (info) => info.getValue()?.fullName,
        header: () => 'Sales Rep',
      }),
      columnHelper.accessor((client) => client.phone, {
        id: 'phone',
        cell: (info) => info.getValue(),
        header: () => 'Phone',
      }),
      columnHelper.accessor((client) => client.email, {
        id: 'email',
        cell: (info) => {
          return info.getValue();
        },
        header: () => 'Email',
      }),
    ],
    [columnHelper]
  );

  const mobileColumns = useMemo(() => ['name', 'primaryContact', 'phone', 'email'], []);
  const table = useReactTable({
    columns,
    data: data?.listClients?.clients || [],
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
    },
  });

  const clearFilters = useCallback(() => {
    setSalesRepFilter('');
    window.localStorage.setItem(SALES_REP_FILTERS_KEY, '');
    setCategoryFilter('');
    window.localStorage.setItem(CLIENT_CATEGORY_FILTERS_KEY, '');
    setFilters({ ...filters, salesRep: '', categoryId: '' });
  }, [filters, setFilters]);

  const handleSalesRepFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    window.localStorage.setItem(SALES_REP_FILTERS_KEY, newValue);
    setSalesRepFilter(newValue);
    setFilters({ ...filters, salesRep: newValue });
  };

  const handleCategoryFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    window.localStorage.setItem(CLIENT_CATEGORY_FILTERS_KEY, newValue);
    setCategoryFilter(newValue);
    setFilters({ ...filters, categoryId: newValue });
  };

  const handleAppliedFiltersChange = () => {
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    window.localStorage.setItem(SALES_REP_FILTERS_KEY, filters?.salesRep ?? '');
    window.localStorage.setItem(CLIENT_CATEGORY_FILTERS_KEY, filters?.categoryId ?? '');
    setAppliedFilters({ ...filters });
  };

  const restorePreviouslySelectedFilters = () => {
    window.localStorage.setItem(SALES_REP_FILTERS_KEY, appliedFilters?.salesRep ?? '');
    window.localStorage.setItem(CLIENT_CATEGORY_FILTERS_KEY, appliedFilters?.categoryId ?? '');
    setFilters({ ...appliedFilters });
  };

  return (
    <Container>
      <PageHeader variant='h1' header='Clients' />
      {!loading && error ? (
        <Alert color='error'>
          <AlertTitle>
            There was a problem loading client data. Please try refreshing the page.
            {error?.message && <ErrorMessage>Error: {error.message}</ErrorMessage>}
          </AlertTitle>
        </Alert>
      ) : (
        <>
          <Flex styles={{ alignItems: 'center', justifyContent: 'space-between' }} gap={1}>
            <Search defaultValue={searchTerms} onEnter={setSearchTerms} placeholder='Search for a client...' />
            <FilterButton onClick={() => setOpenDrawer((prev) => !prev)} isFilterApplied={filtersApplied} />
            <FilterMenu
              openDrawer={openDrawer}
              setOpenDrawer={setOpenDrawer}
              clearFilters={clearFilters}
              salesRepFilter={filters.salesRep}
              handleSalesRepFilterChange={handleSalesRepFilterChange}
              salesReps={salesReps}
              categoryFilter={filters.categoryId}
              handleCategoryFilterChange={handleCategoryFilterChange}
              categories={clientCodes}
              restorePreviouslySelectedFilters={restorePreviouslySelectedFilters}
              handleAppliedFiltersChange={handleAppliedFiltersChange}
            />
          </Flex>
          <Flex column w100 center>
            <Table>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header, i) => {
                      if (isTablet && !mobileColumns.includes(header.id)) {
                        return; // exclude certain columns from mobile sizes
                      }
                      return (
                        <th key={header.id} colSpan={header.colSpan}>
                          {header.isPlaceholder ? null : (
                            <TableHeaderCell $start={i === 0} $end={header.id === 'email'}>
                              {flexRender(header.column.columnDef.header, header.getContext())}
                            </TableHeaderCell>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {loading && (
                  <Row>
                    <td colSpan={6} align='center'>
                      <LoadingSpinner loading={loading} />
                    </td>
                  </Row>
                )}
                {!loading &&
                  data?.listClients?.clients &&
                  table.getRowModel().rows.map((row) => {
                    return (
                      <Row key={row.id} onMouseEnter={() => {}} onMouseLeave={() => {}}>
                        {row.getVisibleCells().map((cell, i) => {
                          if (isTablet && !mobileColumns.includes(cell.column.id)) return; // exclude certain columns from mobile sizes

                          return (
                            <StyledTableCell
                              style={cell.column.id === 'email' ? {} : { cursor: 'pointer' }}
                              $start={i === 0}
                              $end={i === row.getVisibleCells().length - 1}
                              $padding={cell.column.id === 'email' && '0'}
                              key={cell.id}
                            >
                              {cell.column.id === 'email' ? (
                                <Spacer component={EmailLink} href={cell.getValue() ? `mailto:${cell.getValue()}` : ''}>
                                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </Spacer>
                              ) : (
                                <Spacer
                                  component={Link}
                                  to={`/client/${seoFriendly(row.original.name ?? '')}-${row.original.id}`}
                                >
                                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </Spacer>
                              )}
                            </StyledTableCell>
                          );
                        })}
                      </Row>
                    );
                  })}
              </tbody>
            </Table>
            <Pagination
              currentPage={table.getState().pagination.pageIndex}
              pages={pages}
              onPageChange={handleSetPage}
              perPage={table.getState().pagination.pageSize}
              onPerPageChange={(pageSize: number) => {
                setPagination((cur) => ({
                  pageIndex: getPage(cur.pageSize, cur.pageIndex, pageSize),
                  pageSize,
                }));
              }}
            />
          </Flex>
        </>
      )}
    </Container>
  );
};

export default Clients;
