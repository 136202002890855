import React, { useState } from 'react';
import { css, styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import Snackbar from '@mui/material/Snackbar';
import InfoIcon from '@mui/icons-material/Info';
import darkmode from 'assets/darkmode.svg';
import lightmode from 'assets/lightmode.svg';
import { opacity, isAdmin } from 'utils/functions';
import LabeledSwitch from 'components/LabeledSwitch/LabeledSwitch';
import { useSelectedThemeContext } from 'contexts/SelectedThemeContext/SelectedThemeContext';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { useAuthContext } from 'contexts/AuthContext/AuthContext';
import Button from 'components/Button/Button';

const Container = styled(Drawer)<{ $adminUser?: boolean; $checked?: boolean }>(
  ({ theme, $adminUser, $checked }) => css`
    .MuiBackdrop-root {
      opacity: 0 !important;
    }

    .MuiDrawer-paperAnchorLeft {
      transition-duration: 0ms !important;
      left: 6rem;
      top: ${$adminUser ? 'calc(100vh - 12rem)' : 'calc(100vh - 8rem)'};
      height: ${$adminUser ? '11rem' : '6.5rem'};
      width: 12rem;
      padding: 1rem;
      border-radius: 0.5rem;
      border-top: 0.0625rem solid ${theme.colors.bevelTop};
      border-left: 0.0625rem solid ${theme.colors.bevelLeft};
      background-color: ${theme.colors.charcoal};
      box-shadow: -0.1875rem 0 0.5rem ${opacity(theme.colors.black, 0.2)};
      z-index: 1000;
      overflow: hidden;
    }

    .MuiSwitch-root {
      &:hover {
        .MuiSwitch-track {
          border: 0.0625rem solid transparent;
        }
      }

      .MuiSwitch-track {
        border: 0.0625rem solid transparent;
        padding-top: 0.375rem;
        background-color: ${theme.colors.lightBlack};

        &:hover {
          border: 0.0625rem solid transparent;
        }
      }

      .MuiSwitch-thumb {
        background-color: ${theme.colors.charcoal} !important;
      }
    }

    ${$checked &&
    css`
      .MuiSwitch-track {
        background-color: ${theme.colors.lighterInput} !important;
      }
    `}
  `
);

const Text = styled('p')(
  ({ theme }) => css`
    margin: 0 0 0.25rem 0;
    font-size: 0.75rem;
    font-weight: 600;
    color: ${theme.colors.whiteOpacity50};
    text-transform: uppercase;
  `
);

const SettingsButton = styled(Button)(
  () => css`
    margin-top: 0.75rem;
  `
);

const StyledInfoIcon = styled(InfoIcon)(
  () => css`
    font-size: 0.875rem;
    margin-left: 0.5rem;
  `
);

type Props = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  mobile?: boolean;
};

const MobileContainer = styled('div')(
  ({ theme }) => css`
    background-color: ${theme.colors.charcoal};
    padding: 1rem;
    border-radius: 1rem;
  `
);

const StyledLabeledSwitch = styled(LabeledSwitch)<{ $checked?: boolean }>(
  ({ theme, $checked }) => css`
    &:hover {
      .MuiSwitch-track {
        border: 0.0625rem solid transparent;
      }
    }

    .MuiSwitch-track {
      border: 0.0625rem solid transparent;
      padding-top: 0.375rem;
      background-color: ${theme.colors.lightBlack};

      &:hover {
        border: 0.0625rem solid transparent;
      }
    }

    .MuiSwitch-thumb {
      background-color: ${theme.colors.charcoal} !important;
    }

    ${$checked &&
    css`
      .MuiSwitch-track {
        background-color: ${theme.colors.lighterInput} !important;
      }
    `}
  `
);

const DarkLightModeToggle: React.FC<Props> = ({ show, setShow, mobile = false }) => {
  const { lightMode, setLightMode } = useSelectedThemeContext();
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>();
  const { roleId, tokens } = useAuthContext();
  const isAdminUser = isAdmin(roleId);

  const clearRedisCache = async (roleId: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API}/redis/cache-clear`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${tokens?.accessToken}`,
        },
        body: `role_id=${roleId}`,
      });
      const cacheInfo = await response.json().then();

      if (cacheInfo?.success) {
        setShowSnackbar(true);
        setSnackbarMessage(cacheInfo.count > 0 ? 'Cache was cleared successfully.' : 'Cache is already clear.');
      }
    } catch (error) {
      setSnackbarMessage(`An error occurred while trying to clear cache: ${error}`);
    }
  };

  if (mobile) {
    if (!show) {
      return null;
    }

    return (
      <MobileContainer>
        <Snackbar
          open={showSnackbar}
          autoHideDuration={5000}
          onClose={() => setShowSnackbar(false)}
          message={snackbarMessage}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        />
        <Text>{lightMode ? 'Light' : 'Dark'} Mode</Text>
        <StyledLabeledSwitch
          checked={lightMode}
          onChange={(e: React.BaseSyntheticEvent) => setLightMode(e.target.checked)}
          disableRipple
          checkedLabel={`url(${lightmode})`}
          uncheckedLabel={`url(${darkmode})`}
        />
        {isAdminUser && (
          <>
            <Text>
              Cache
              <Tooltip title='Button will clear cache for vendors, employees, markets, and supervisors.'>
                <StyledInfoIcon />
              </Tooltip>
            </Text>
            <SettingsButton onClick={() => clearRedisCache(roleId)}>Clear Cache</SettingsButton>
          </>
        )}
      </MobileContainer>
    );
  }

  return (
    <>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={5000}
        onClose={() => setShowSnackbar(false)}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      <Container
        anchor='left'
        $checked={lightMode}
        disableScrollLock
        $adminUser={isAdminUser}
        onClose={() => setShow(false)}
        open={show}
      >
        <Text>{lightMode ? 'Light' : 'Dark'} Mode</Text>
        <LabeledSwitch
          checked={lightMode}
          onChange={(e: React.BaseSyntheticEvent) => setLightMode(e.target.checked)}
          disableRipple
          checkedLabel={`url(${lightmode})`}
          uncheckedLabel={`url(${darkmode})`}
        />
        {isAdminUser && (
          <>
            <Text>
              Cache
              <Tooltip title='Button will clear cache for vendors, employees, markets, and supervisors.'>
                <StyledInfoIcon />
              </Tooltip>
            </Text>
            <SettingsButton onClick={() => clearRedisCache(roleId)}>Clear Cache</SettingsButton>
          </>
        )}
      </Container>
    </>
  );
};

export default DarkLightModeToggle;
