import { createGlobalStyle, css } from 'styled-components';

export const ModalGlobalStyle = createGlobalStyle<{ $isModalOpen?: boolean }>(
  ({ theme, $isModalOpen }) => css`
    @media (max-width: ${theme.breakpoints.values.tablet}px) {
      body {
        overflow: ${$isModalOpen ? 'hidden !important' : 'auto'};
      }
    }
  `
);
